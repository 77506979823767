/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
  MouseEvent,
} from 'react'
import {
  VictoryChart,
  VictoryBoxPlot,
  VictoryAxis,
  VictoryLegend,
} from 'victory'
import { handleFormatLabel, handleFormatLeftAxis } from 'utils/timeFormats'
import { usePrevious } from 'utils/hooks'
import terms from 'common/terms'
import { Zone } from 'reducers/zones/types'
import { Widget } from 'reducers/boards/types'
import { RequestManager } from '@osrdata/app_core/dist/requests'
import Loader from 'components/Loader/Loader'

import './Boxplot.scss'

type Props = {
  widget: Widget
  zone: Zone
  requestManager?: RequestManager
  isPreview?: boolean
  isConfLoading?: boolean
  canceled?: boolean
  onMetricLoad?: Dispatch<SetStateAction<boolean>>
}

type MTBFMetric = {
  q1: number
  q3: number
  median: number
  min: number
  max: number
  x: string
}

type Metric = {
  meta: {
    [key: string]: string
  }
  data: MTBFMetric[]
}

const BoxplotWidget = ({
  widget, zone, requestManager, isPreview, isConfLoading, canceled, onMetricLoad,
}: Props): ReactElement => {
  const rq = requestManager || new RequestManager()
  const [metricsData, setMetricsData] = useState<Metric>({ meta: {}, data: [] })
  const [loading, setLoading] = useState<boolean>(false)
  const [dataSet, setDataSet] = useState<MTBFMetric[]>([])
  const [error, setError] = useState<string>('')
  const filterParams = (widget?.filterParams || []).concat(widget?.parameters)
  const previousParams = usePrevious(filterParams)
  const [hovered, setHovered] = useState(false)
  const [labelText, setLabelText] = useState('')
  const [labelValue, setLabelValue] = useState(0)
  const [labelPositionX, setLabelPositionX] = useState(0)
  const [labelPositionY, setLabelPositionY] = useState(0)
  const unit = metricsData.meta?.unit

  const loadMetrics = async () => {
    setLoading(true)

    try {
      rq.abort()
      const response = await rq.get(
        `/usage_reseau/metrics/${widget?.metric_slug}/boxplot`, filterParams.reduce(
          (cur, acc) => {
            cur[acc.slug] = Array.isArray(acc.value) ? acc.value.join(',') : acc.value
            return cur
          }, { zone_id: zone.id },
        ),
      ) as unknown as Metric
      setMetricsData(response)
      setError('')
    } catch (e) {
      if (e?.response?.data?.detail) {
        setError(e.response.data.detail)
      } else if (e?.message === 'canceled') {
        // Set error to empty string to avoid displaying error message when request is canceled
        setError(' ')
      } else {
        setError(terms.Widgets.errorLoadingData)
      }
    }

    setLoading(false)
  }

  useEffect(() => {
    onMetricLoad?.(loading)
  }, [loading])

  useEffect(() => {
    if (!widget || !zone || canceled) {
      return
    }

    if (!isPreview) {
      loadMetrics()
    } else if (JSON.stringify(previousParams) !== JSON.stringify(filterParams)) {
      loadMetrics()
    }
  }, [widget, zone])

  useEffect(() => {
    setDataSet(metricsData?.data)
  }, [metricsData?.data])

  if (isConfLoading || loading) {
    return <div className="boxplot-widget"><Loader /></div>
  }

  if (canceled) {
    return <div className="boxplot-widget"><p>Chargement de la prévisualisation annulé</p></div>
  }

  if ((metricsData?.data?.length === 0 || error)) {
    return <div className="boxplot-widget"><p>{error || terms.Widgets.noData}</p></div>
  }

  return (
    <div className="boxplot-widget">
      <VictoryChart
        domainPadding={{ x: Math.max(0, 400 / (dataSet.length || 1)) }}
        padding={{
          top: 30, left: 20, right: 20, bottom: 80,
        }}
        animate={{ duration: 2000, onLoad: { duration: 1000 } }}
      >
        <VictoryLegend
          x={125}
          y={0}
          orientation="horizontal"
          gutter={20}
          style={{ title: { fontSize: 6, color: '#747678' }, labels: { fill: 'navy', fontSize: 8 } }}
          data={[
            { name: 'Q1', symbol: { fill: 'tomato', type: 'square' } },
            { name: 'Médiane', symbol: { fill: '#0088ce', type: 'square' } },
            { name: 'Q3', symbol: { fill: 'orange', type: 'square' } },
          ]}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={x => (handleFormatLabel(x, unit))}
          style={{ tickLabels: { fontSize: 6, padding: 2 } }}
        />
        <VictoryAxis
          crossAxis
          tickFormat={tick => (tick >= 0 ? tick.replace(/(.{50})/g, '$1\n') : `${tick.replace(/ *\([^)]*\) */g, '').replace(/(.{50})/g, '$1\n')}`)}
          style={{
            tickLabels: {
              fontSize: 4,
              padding: 2,
              angle: 45,
              textAnchor: 'start',
            },
          }}
        />
        <VictoryBoxPlot
          boxWidth={Math.max(5, 35 / dataSet.length)}
          data={dataSet}
          style={{
            min: { stroke: 'tomato', strokeWidth: 3 },
            max: { stroke: 'orange', strokeWidth: 3 },
            q1: { fill: 'tomato' },
            q3: { fill: 'orange' },
            median: { stroke: '#0088ce', strokeWidth: 4 },
            minLabels: { fill: 'tomato' },
            maxLabels: { fill: 'orange' },
          }}
          events={[{
            target: 'median',
            eventHandlers: {
              onMouseOver: (e: MouseEvent) => {
                setHovered(true)
                setLabelPositionX(e.nativeEvent.offsetX)
                setLabelPositionY(e.nativeEvent.offsetY - 40)
                return [{
                  target: 'median',
                  mutation: props => {
                    setLabelText('Médiane')
                    setLabelValue(props.datum.median)
                  },
                }]
              },
              onMouseLeave: () => {
                setHovered(false)
              },
            },
          },
          {
            target: 'q1',
            eventHandlers: {
              onMouseOver: (e: MouseEvent) => {
                setHovered(true)
                setLabelPositionX(e.nativeEvent.offsetX)
                setLabelPositionY(e.nativeEvent.offsetY - 40)
                return [{
                  target: 'q1',
                  mutation: props => {
                    setLabelText('Q1')
                    setLabelValue(props.datum.q1)
                  },
                }]
              },
              onMouseLeave: () => {
                setHovered(false)
              },
            },
          },
          {
            target: 'q3',
            eventHandlers: {
              onMouseOver: (e: MouseEvent) => {
                setHovered(true)
                setLabelPositionX(e.nativeEvent.offsetX)
                setLabelPositionY(e.nativeEvent.offsetY - 40)
                return [{
                  target: 'q3',
                  mutation: props => {
                    setLabelText('Q3')
                    setLabelValue(props.datum.q3)
                  },
                }]
              },
              onMouseLeave: () => {
                setHovered(false)
              },
            },
          },
          {
            target: 'max',
            eventHandlers: {
              onMouseOver: (e: MouseEvent) => {
                setHovered(true)
                setLabelPositionX(e.nativeEvent.offsetX)
                setLabelPositionY(e.nativeEvent.offsetY - 40)
                return [{
                  target: 'max',
                  mutation: props => {
                    setLabelText('Max')
                    setLabelValue(props.datum.max)
                  },
                }]
              },
              onMouseLeave: () => {
                setHovered(false)
              },
            },
          },
          {
            target: 'min',
            eventHandlers: {
              onMouseOver: (e: MouseEvent) => {
                setHovered(true)
                setLabelPositionX(e.nativeEvent.offsetX)
                setLabelPositionY(e.nativeEvent.offsetY - 40)
                return [{
                  target: 'min',
                  mutation: props => {
                    setLabelText('Min')
                    setLabelValue(props.datum.min)
                  },
                }]
              },
              onMouseLeave: () => {
                setHovered(false)
              },
            },
          },
          ]}
        />
      </VictoryChart>
      {hovered && (
        <div className="tooltip" style={{ left: labelPositionX, top: labelPositionY }}>
          {`${labelText} : ${handleFormatLeftAxis(labelValue, unit)}`}
        </div>
      )}
    </div>
  )
}

BoxplotWidget.defaultProps = {
  isPreview: false,
  isConfLoading: false,
  canceled: false,
  requestManager: undefined,
  onMetricLoad: () => { /* TO IMPLEMENT */ },
}

export default BoxplotWidget
